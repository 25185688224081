import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import jaLocale from "@fullcalendar/core/locales/ja";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import axios from "axios";
import _ from "lodash";

// mui追加
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Backdrop from '@mui/material/Backdrop';
//import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//import Snackbar from '@mui/material/Snackbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import InputAdornment from '@mui/material/InputAdornment';


import toast, { Toaster } from 'react-hot-toast';

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LinkIcon from '@mui/icons-material/Link';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SearchIcon from '@mui/icons-material/Search';

// import OutboxIcon from '@mui/icons-material/Outbox';
// import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";

import useMediaQuery from '@mui/material/useMediaQuery';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { useSwipeable } from "react-swipeable"; // スワイプイベント検知

// styled

// withStyles

// themeにpalette追加
const theme = createTheme({
    palette: {
        // // 真紅
        // shinku: {
        //   light: "#d9333f",
        //   main: "#ad002d",
        //   dark: "#a22041",
        //   contrastText: "#e9e4d4",
        // },

        // 背景色グレー
        BGgrey: { main: grey[300] },
        BGwhitegrey: { main: grey[50] },
        BGgrey500: { main: grey[500] },

        // 以下、予定のカラーリング g〇〇gleカレンダーの色を拝借
        // デフォルト（ピーコック）
        GCdefault: { main: "#039BE5" },
        // トマト
        GCtomato: { main: "#D50000" },
        // フラミンゴ
        GCflamingo: { main: "#E67C73" },
        // ミカン
        GCorange: { main: "#F4511E" },
        // バナナ
        GCbanana: { main: "#F6BF26" },
        // セージ
        GCsage: { main: "#33B679" },
        // バジル
        GCbasil: { main: "#0B8043" },
        // ブルーベリー
        GCblueberry: { main: "#3F51B5" },
        // ラベンダー
        GClavender: { main: "#7986CB" },
        // ブドウ
        GCgrape: { main: "#8E24AA" },
        // グラファイト
        GCgraphite: { main: "#616161" },
    },
    components: {
        MuiMenu: {
            styleOverrides: {
                list: {
                    padding: "0",
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "0px",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                }
            }
        }
    },
});

//const styles = {
//    dialogPaper: {
//        minHeight: '70vh',
//        maxHeight: '70vh',
//    }
//}


//const cors =require('cors')

// 変更前のstateを持っておくカスタムHook 
function usePrevious(value) {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function App() {
    const [events, setEvents] = useState([]);
    const [eventGuid, setEventGuid] = useState(0);

    // handleDateUnselect用に、選択中のselectionInfoを保持してもらう
    const [currentSelectionInfo, setCurrentSelectionInfo] = useState({});
    const [addEventDialog, setAddEventDialog] = useState(false); // 予定追加ダイアログを起動するか否か
    const [isEdit, setIsEdit] = useState(false);
    const [currentAddText, setCurrentAddText] = useState("");
    const [currentEventColorObj, setCurrentEventColorObj] = useState({
        name: "GCdefault",
        code: "#039BE5",
    });
    const [currentCanAllBeEdited, setCurrentCanAllBeEdited] = useState(false); // currentEventが作成者以外も編集・削除出来るか否かの状態を持つ
    const [tmpCurrentAllBeEdited, setTmpCurrentAllBeEdited] = useState(false); // チェックボックスの挙動で↑を変えると表示の処理が大変なので、こっちで拾ってAPIへ流し込む

    // const [eventColorAnchorEl, setEventColorAnchorEl] = useState(null);
    // 色選択メニューを配置するHTML要素を格納
    const anchorEl = useRef(null);
    const [eventColorMenuOpen, setEventColorMenuOpen] = useState(false);
    const [currentEventNotification, setCurrentEventNotification] = useState([]);
    const [circularProgressIsActive, setCircularProgressIsActive] = useState(false);
    const [linearProgressIsActive, setLinearProgressIsActive] = useState(false);
    const currentPageDateRef = useRef({ start: "", end: "" });
    const [errorDialog, setErrorDialog] = useState([false, ""]); // 認証失敗時・不正URL時等に起動
    const [confirmToDelete, setConfirmToDelete] = useState(false);
    const [eventShareDialog, setEventShareDialog] = useState(false);
    const [shareMyroomList, setShareMyroomList] = useState([]);
    const [shareGroupList, setShareGroupList] = useState([]);
    const [shareContactList, setShareContactList] = useState([]);
    const [shareRoomidList, setShareRoomidList] = useState([]);
    const [shareRoomnameList, setShareRoomnameList] = useState([]);
    const [shareListLoading, setShareListLoading] = useState(false);
    const [currentIsAnotherEvent, setCurrentIsAnotherEvent] = useState(false); // 予定ダイアログの編集許可で使う
    const [searchDialog, setSearchDialog] = useState(false);
    const [searchedEvents, setSearchedEvents] = useState([]);
    const [searchedMessage, setSearchedMessage] = useState("");
    const [invisibleSharedRoomidList, setInvisibleSharedRoomidList] = useState([]);

    const calendarRef = useRef(null);

    const isSpDevice = useMediaQuery(theme.breakpoints.down('sm')); // レスポンシブ判定 600px未満ならtrue

    const handlers = useSwipeable({
        onSwiped: (event) => {     
            if (event.dir === "Left") {
                // 左にスワイプしたときに発火するイベント
                // console.log("←←←左スワイプしたぞおおお")
                // 次の月へ
                calendarRef.current.calendar.next()
            }
            if (event.dir === "Right") {
                // 右にスワイプしたときに発火するイベント
                // console.log("右スワイプしたぞおおお→→→")
                // 前の月へ
                calendarRef.current.calendar.prev()
                
            }
        },
        trackMouse: false, //マウス操作でのスワイプを許可する場合はtrue
    });

    // vueで言うところのwatchのoldValみたいなやつ
    const prevSelectioninfo = usePrevious(currentSelectionInfo);


    // muiではテーマカラーしか使えないが、Fullcalendarではテーマカラーは使えないのでこれで変換😭
    const eventColorMember = [
        { name: "GCdefault", code: "#039BE5" },
        { name: "GCtomato", code: "#D50000" },
        { name: "GCflamingo", code: "#E67C73" },
        { name: "GCorange", code: "#F4511E" },
        { name: "GCbanana", code: "#F6BF26" },
        { name: "GCsage", code: "#33B679" },
        { name: "GCbasil", code: "#0B8043" },
        { name: "GCblueberry", code: "#3F51B5" },
        { name: "GClavender", code: "#7986CB" },
        { name: "GCgrape", code: "#8E24AA" },
        { name: "GCgraphite", code: "#616161" },
    ];

    const search = useLocation().search; // URL path や パラメータなど。JSのlocationと同じ
    const query = new URLSearchParams(search);
    const paramhonbuid = query.get('honbuid');
    const paramroomid = query.get('roomid');

    // 初回起動時
    useEffect(() => {
        (async () => {
        if (paramhonbuid&&paramroomid ) {
            //初回のみロードのクルクル表示
            setCircularProgressIsActive(true);
            localStorage.setItem("honbuid", paramhonbuid);
            // 認証処理
            //console.log("check start")
            await fetch(`/check?honbuid=${paramhonbuid}&roomid=${paramroomid}`
            ).then((response) => response.json()
            ).then(async (responseJson) => {
                //console.log("check ok")
                if (responseJson.status === 401) {
                    setErrorDialog([true,"auth"]);
                    return;
                }
                localStorage.setItem('apikey', responseJson.key);
                localStorage.setItem("roomid", responseJson.roomid);
                await getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
            }).catch((err) => {
                console.log("check error", err);
            })
        } else {
            // 不正なURLです的な警告出す？
            //console.log("paramなし");
            setErrorDialog([true, "param"]);
        }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // currentSelectionInfo.startが動いた時、予定を削除
    useEffect(() => {
        if (prevSelectioninfo&&
            prevSelectioninfo.startStr &&
            currentSelectionInfo.startStr &&
            prevSelectioninfo.startStr !== currentSelectionInfo.startStr) {
                setCurrentEventNotification([]);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectionInfo.start])


    // 月変更イベント
    const handleDateChange = async (event) => {
        const getStartDate = dayjs(event.start).format("YYYY-MM-DD 00:00:00");
        const getEndDate = dayjs(event.end).format("YYYY-MM-DD 00:00:00");
        if (!paramhonbuid) return; 
        if (currentPageDateRef.current.start !== ""
            && currentPageDateRef.current.start !== getStartDate) {
            // 月移動時 get実行
            getAndApplyEvents(getStartDate, getEndDate);
        }
        // 最後にcurrentPageDateRef更新
        currentPageDateRef.current = { start: getStartDate, end: getEndDate }
    };

    // 予定追加ダイアログ起動時
    const handleDateSelect = async(selectionInfo) => {
        // 予定作成時にドラッグして日付複数選択してきたか判定する
        // diffDates: end - start（日単位換算）
        dayjs.locale(ja);
        const diffDates = dayjs(selectionInfo.end).diff(
            dayjs(selectionInfo.start),
            "day"
        );

        // dateClickで呼び出した時（スマホ版）：start,startStrがない（変わりにdate,dateStr）
        // date,dateStrの内容でstart,startStrを作っておく
        if (selectionInfo.date&&selectionInfo.dateStr) {
            selectionInfo.start = selectionInfo.date;
            selectionInfo.startStr = selectionInfo.dateStr;
        }

        // ドラッグ選択でない時
        if (diffDates < 2) {
            // endの初期値をstartの1時間後に
            selectionInfo.end = dayjs(selectionInfo.start).add(1, "h").$d;
            // endStrも同様
            selectionInfo.endStr = dayjs(selectionInfo.start)
                .add(1, "h")
                .format("YYYY-MM-DD");
        } else {
            // endの初期値をendの1日前に
            selectionInfo.end = dayjs(selectionInfo.end).subtract(1, "d").$d;
            // endStrも同様
            selectionInfo.endStr = dayjs(selectionInfo.end)
                .subtract(1, "d")
                .format("YYYY-MM-DD");
        }

        // 予定ダイアログ起動時にshare設定も取ってきておく
        await handleShareGet(selectionInfo);
        setCurrentEventColorObj({ name: "GCdefault", code: "#039BE5" });
        setCurrentSelectionInfo(selectionInfo);
        setCurrentAddText("");
        setCurrentCanAllBeEdited(false);
        setTmpCurrentAllBeEdited(false);
        setAddEventDialog(true);
    };

    // 予定追加ダイアログ脱出時
    const handleDateUnselect = () => {
        setAddEventDialog(false);
        setCurrentSelectionInfo({});
        setCurrentAddText("");
        setCurrentCanAllBeEdited(false);
        setTmpCurrentAllBeEdited(false);
        setCurrentEventColorObj({ name: "GCdefault", code: "#039BE5" });
        setCurrentEventNotification([]);
        setShareGroupList([]);
        setShareContactList([]);
        setShareRoomidList([]);
        setShareRoomnameList([]);
        setCurrentIsAnotherEvent(false);
        setInvisibleSharedRoomidList([]);
        setIsEdit(false);
    };

    // fetch get →ローカルのeventsに反映
    const getAndApplyEvents = async(forward,before) => {
        //console.log("get start")
        setLinearProgressIsActive(true);
        const LShonbuid = localStorage.getItem('honbuid') 
        const LSroomid = localStorage.getItem('roomid')

        // get範囲は、カレンダー表示始点-35日(5週間)～カレンダー表示終点+35日(5週間)
        const extendedForward = dayjs(forward).subtract(35, 'd').format("YYYY-MM-DD 00:00:00");
        const extendedBefore = dayjs(before).add(35, 'd').format("YYYY-MM-DD 00:00:00");

        //apikeyをheaderに混ぜてget
        if (paramhonbuid) {
            await fetch(`/events?honbuid=${LShonbuid}&roomid=${LSroomid}&forward=${extendedForward}&before=${extendedBefore}`,
                { headers: { apikey: localStorage.getItem('apikey') } }
            ).then((response) => response.json()
            ).then((responseJson) => {
                handleApplyEvent(responseJson.eventslist, responseJson.notislist, "events")
                //console.log("get ok")
            }).catch((err) => {
                console.log("get error", err);
            })
            setCircularProgressIsActive(false);
            setLinearProgressIsActive(false);
        }
    }

    // get取得した情報をもとに、stateのevents更新
    const handleApplyEvent = (eventslist, notislist, target) => {
        let tmpevents = []
        for (const event of eventslist) {
            //eventのidでnotisList(通知リスト)を走査
            const filtednotis = notislist.filter(noti => 
                noti.notiEventid === event.id
            )
            // events内のnotificationに合わせた形に整える [{time:"YYYY-MM-DD HH:mm"},...]
            const formatedfiltednotis = [];
            if (filtednotis.length > 0) {
                for (const filtednoti of filtednotis) {
                    formatedfiltednotis.push({
                        time: dayjs(filtednoti.notification).format("YYYY-MM-DD HH:mm")
                    })
                }
            }
            // allDayの値によってstart,endをformatする
            // Fullcalendarに渡す時は特定の形にformatする必要がある
            let formatedstart = "";
            let formatedend = "";
            if (event.allDay === "1") {
                // 全日のときはYYYY-MM-DD
                formatedstart = dayjs(event.start).format("YYYY-MM-DD");
                formatedend = dayjs(event.end).format("YYYY-MM-DD");
            } else {
                // 時間指定のときはYYYY-MM-DDTHH:mm
                formatedstart = dayjs(event.start).format("YYYY-MM-DDTHH:mm");
                formatedend = dayjs(event.end).format("YYYY-MM-DDTHH:mm");
            }
            tmpevents.push({
                id: event.id,
                honbuid: event.honbuid,
                name: event.name,
                title: event.title,
                start: formatedstart,
                end: formatedend,
                allDay: event.allDay === "1" ? true:false,
                backgroundColor: event.backgroundColor,
                eventColorObj: JSON.parse(event.eventColorObj),
                borderColor: event.borderColor,
                addText: event.addText,
                canAllBeEdited: event.canAllBeEdited ==="1" ?true:false,
                notification: formatedfiltednotis
            })
        }
        if (target === "events") {
            // tmpeventsでstateのeventsを一気に更新
            setEvents(tmpevents);
        } else if (target === "searchedEvents") {
            setSearchedEvents(tmpevents);
        }
    }

    // 配列オブジェクトの重複削除
    const uniqueObjInArr = (argObjInArr) => {
        return Array.from(
            new Map(argObjInArr.map((obj) => [obj.time, obj])).values()
        );
    }

    // 予定を追加
    const handleAddEvent = () => {
        // Ⅰ.終日 開始-終了 → id,title,start,end,allDay (start,endは'YYYY-MM-DD')
        // Ⅱ.時間追加 開始-終了 → id,title,start,end,allDay (start,endは'YYYY-MM-DDTHH:MM:SS')

        // allDayの時のみ、カレンダーの描画が直感的ではない（endが1日前倒しで表示される）ので、
        // setEvent時にendを1日足す（編集などでcurrentに取り込むときには1日引いて取得するようにする）

        // 共有設定の登録がある時、（shareRoomidList.length > 0）タイトルの頭に🔗を付ける
        const eventTitleHeader = shareRoomidList.length > 0 ? "🔗" : "";

        if (currentSelectionInfo.allDay) {
            // Ⅰのパターン
            setEvents([
                ...events,
                {
                    id: createEventId(),
                    // groupId もつけれるみたい 後々必要になって来るかも
                    // honbuid
                    honbuid: paramhonbuid,
                    title: eventTitleHeader + (currentSelectionInfo.title
                        ? currentSelectionInfo.title
                        : "(タイトル未入力)"),
                    start: dayjs(currentSelectionInfo.start).format("YYYY-MM-DD"),
                    end: dayjs(currentSelectionInfo.end).add(1, "d").format("YYYY-MM-DD"),
                    allDay: currentSelectionInfo.allDay,
                    backgroundColor: currentEventColorObj.code,
                    eventColorObj: currentEventColorObj,
                    // borderColor: "#eceff1",
                    borderColor: currentEventColorObj.code,
                    addText: currentAddText,
                    canAllBeEdited: tmpCurrentAllBeEdited,
                    notification: uniqueObjInArr(currentEventNotification),
                },
            ]);

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    //id: createEventId(),
                    groupId: localStorage.getItem('roomid'),
                    honbuid: paramhonbuid,
                    title: eventTitleHeader + (currentSelectionInfo.title
                        ? currentSelectionInfo.title
                        : "(タイトル未入力)"),
                    start: dayjs(currentSelectionInfo.start).format("YYYY/MM/DD 00:00:00"),
                    end: dayjs(currentSelectionInfo.end).add(1, "d").format("YYYY/MM/DD 00:00:00"),
                    allDay: currentSelectionInfo.allDay ? "1" : "0",
                    backgroundColor: currentEventColorObj.code,
                    eventColorObj: JSON.stringify(currentEventColorObj),
                    borderColor: currentEventColorObj.code,
                    addText: currentAddText,
                    canAllBeEdited: tmpCurrentAllBeEdited ? "1" : "0",
                    notifications: uniqueObjInArr(currentEventNotification),
                })
            };
            //console.log("post insert start")
            fetch("/events", requestOptions
            ).then((response) => response.json()
            ).then((responseJson) => {
                // 新しいidを使い、公開設定を再度登録
                upRoomidToShareWithNewEventid(responseJson, shareRoomidList);
                // ブラウザ版の時、post成功したらsnackbar表示
                if (!isSpDevice) {
                    //setSnackbarState([true, "予定を追加しました。"]);
                    toast.success('予定を追加しました。')
                }
                // post成功したらget
                getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
            }).catch((err) => {
                console.log("post insert error", err);
            })
        } else {
            // Ⅱのパターン
            setEvents([
                ...events,
                {
                    id: createEventId(),
                    // groupId もつけれるみたい 後々必要になって来るかも
                    honbuid: paramhonbuid,
                    title: eventTitleHeader + (currentSelectionInfo.title
                        ? currentSelectionInfo.title
                        : "(タイトル未入力)"),
                    start: dayjs(currentSelectionInfo.start).format("YYYY-MM-DDTHH:mm"),
                    end: dayjs(currentSelectionInfo.end).format("YYYY-MM-DDTHH:mm"),
                    allDay: currentSelectionInfo.allDay,
                    backgroundColor: currentEventColorObj.code,
                    eventColorObj: currentEventColorObj,
                    // borderColor: "#eceff1",
                    borderColor: currentEventColorObj.code,
                    addText: currentAddText,
                    canAllBeEdited: tmpCurrentAllBeEdited,
                    notification: uniqueObjInArr(currentEventNotification),
                },
            ]);

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    //id: createEventId(),
                    groupId: localStorage.getItem('roomid'),
                    honbuid: paramhonbuid,
                    title: eventTitleHeader + (currentSelectionInfo.title
                        ? currentSelectionInfo.title
                        : "(タイトル未入力)"),
                    start: dayjs(currentSelectionInfo.start).format("YYYY/MM/DD HH:mm:00"),
                    end: dayjs(currentSelectionInfo.end).format("YYYY/MM/DD HH:mm:00"),
                    allDay: currentSelectionInfo.allDay ? "1" : "0",
                    backgroundColor: currentEventColorObj.code,
                    eventColorObj: JSON.stringify(currentEventColorObj),
                    // borderColor: "#eceff1",
                    borderColor: currentEventColorObj.code,
                    addText: currentAddText,
                    canAllBeEdited: tmpCurrentAllBeEdited ? "1" : "0",
                    notifications: uniqueObjInArr(currentEventNotification),
                })
            };
            //console.log("post insert start")
            fetch("/events", requestOptions
            ).then((response) => response.json()

            ).then((responseJson) => {
                // 新しいidを使い、公開設定を再度登録
                upRoomidToShareWithNewEventid(responseJson, shareRoomidList);
                // ブラウザ版の時、post成功したらsnackbar表示
                if (!isSpDevice) {
                    //setSnackbarState([true, "予定を追加しました。"]);
                    toast.success('予定を追加しました。')
                }
                // post成功したらget
                getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
            }).catch((err) => {
                console.log("post insert error", err);
            })
        }
        
        handleDateUnselect(); // 最後に脱出時の処理を入れる
    };

    // idをもとにeventsの要素削除
    const deleteEvent = async() => {
        // state event delete
        setEvents(events.filter((event) => event.id !== currentSelectionInfo.id));

        // to鯖 delete
        const deleteParam = {
            eventid: currentSelectionInfo.id,
            honbuid: paramhonbuid,
            roomid: localStorage.getItem('roomid')
        }
        const parameter = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(deleteParam)
            }
        const result = await fetch("/events", parameter).then((response) => {
            return response.json();
        });
        if (result && !isSpDevice) {
        // ブラウザ版の時、delete成功したらsnackbar表示
            //setSnackbarState([true, "予定を削除しました。"]);
            toast.success('予定を削除しました。')
        }
        //console.log("delete end", result);
        getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
    };

    const handleDeleteEvent = () => {
        deleteEvent();
        setSearchedEvents([]);
        setSearchedMessage("");
        setSearchDialog(false);

        handleDateUnselect();
        setConfirmToDelete(false);
    };

    // Eventsを編集（実質DeleteInsert）
    const handleEditEvent = () => {
        const eventTitleHeader = shareRoomidList.length > 0 ? "🔗" : "" ;

        if (currentSelectionInfo.allDay) {
            // Ⅰのパターン
            setEvents([
                ...events.filter((event) => event.id !== currentSelectionInfo.id),
                {
                    id: createEventId(),
                    // groupId もつけれるみたい 後々必要になって来るかも
                    honbuid: paramhonbuid,
                    title: eventTitleHeader + (currentSelectionInfo.title
                        ? currentSelectionInfo.title
                        : "(タイトル未入力)"),
                    start: dayjs(currentSelectionInfo.start).format("YYYY-MM-DD"),
                    end: dayjs(currentSelectionInfo.end).add(1, "d").format("YYYY-MM-DD"),
                    allDay: currentSelectionInfo.allDay,
                    backgroundColor: currentEventColorObj.code,
                    eventColorObj: currentEventColorObj,
                    // borderColor: "#eceff1",
                    borderColor: currentEventColorObj.code,
                    addText: currentAddText,
                    canAllBeEdited: tmpCurrentAllBeEdited,
                    notification: uniqueObjInArr(currentEventNotification),
                },
            ]);
            // id付きでpost 鯖側でdeleteinsert
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: currentSelectionInfo.id,
                    groupId: localStorage.getItem('roomid'),
                    honbuid: paramhonbuid,
                    title: eventTitleHeader + (currentSelectionInfo.title
                        ? currentSelectionInfo.title
                        : "(タイトル未入力)"),
                    start: dayjs(currentSelectionInfo.start).format("YYYY/MM/DD 00:00:00"),
                    end: dayjs(currentSelectionInfo.end).add(1, "d").format("YYYY/MM/DD 00:00:00"),
                    allDay: currentSelectionInfo.allDay ? "1" : "0",
                    backgroundColor: currentEventColorObj.code,
                    eventColorObj: JSON.stringify(currentEventColorObj),
                    borderColor: currentEventColorObj.code,
                    addText: currentAddText,
                    canAllBeEdited: tmpCurrentAllBeEdited ? "1" : "0",
                    notifications: uniqueObjInArr(currentEventNotification),
                })
            };
            //console.log("post deleteinsert start")
            fetch("/events", requestOptions
            ).then((response) => response.json()
            ).then((responseJson) => {
                // ブラウザ版の時、post成功したらsnackbar表示
                // 新しいidを使い、公開設定を再度登録
                upRoomidToShareWithNewEventid(responseJson, shareRoomidList);
                if (!isSpDevice) {
                    //setSnackbarState([true, "予定を変更しました。"]);
                    toast.success('予定を変更しました。')
                }
                // post成功したらget
                getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
            }).catch((err) => {
                console.log("post deleteinsert error", err);
            })

            
        } else {
            // Ⅱのパターン
            setEvents([
                ...events.filter((event) => event.id !== currentSelectionInfo.id),
                {
                    id: createEventId(),
                    // groupId もつけれるみたい 後々必要になって来るかも
                    honbuid: paramhonbuid,
                    title: eventTitleHeader + (currentSelectionInfo.title
                        ? currentSelectionInfo.title
                        : "(タイトル未入力)"),
                    start: dayjs(currentSelectionInfo.start).format("YYYY-MM-DDTHH:mm"),
                    end: dayjs(currentSelectionInfo.end).format("YYYY-MM-DDTHH:mm"),
                    allDay: currentSelectionInfo.allDay,
                    backgroundColor: currentEventColorObj.code,
                    eventColorObj: currentEventColorObj,
                    // borderColor: "#eceff1",
                    borderColor: currentEventColorObj.code,
                    addText: currentAddText,
                    canAllBeEdited: tmpCurrentAllBeEdited,
                    notification: uniqueObjInArr(currentEventNotification),
                },
            ]);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: currentSelectionInfo.id,
                    groupId: localStorage.getItem('roomid'),
                    honbuid: paramhonbuid,
                    title: eventTitleHeader + (currentSelectionInfo.title
                        ? currentSelectionInfo.title
                        : "(タイトル未入力)"),
                    start: dayjs(currentSelectionInfo.start).format("YYYY/MM/DD HH:mm:00"),
                    end: dayjs(currentSelectionInfo.end).format("YYYY/MM/DD HH:mm:00"),
                    allDay: currentSelectionInfo.allDay ? "1" : "0",
                    backgroundColor: currentEventColorObj.code,
                    eventColorObj: JSON.stringify(currentEventColorObj),
                    // borderColor: "#eceff1",
                    borderColor: currentEventColorObj.code,
                    addText: currentAddText,
                    canAllBeEdited: tmpCurrentAllBeEdited ? "1" : "0",
                    notifications: uniqueObjInArr(currentEventNotification),
                })
            };
            //console.log("post deleteinsert start")
            fetch("/events", requestOptions
            ).then((response) => response.json()

            ).then((responseJson) => {
                // 新しいidを使い、公開設定を再度登録
                upRoomidToShareWithNewEventid(responseJson, shareRoomidList);
                // ブラウザ版の時、post成功したらsnackbar表示
                if (!isSpDevice) {
                    //setSnackbarState([true, "予定を変更しました。"]);
                    toast.success('予定を変更しました。')
                }
                // post成功したらget
                getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
            }).catch((err) => {
                console.log("post deleteinsert error", err);
            })
        }
        // 最後に脱出処理
        setSearchedEvents([]);
        setSearchedMessage("");
        setSearchDialog(false);
        handleDateUnselect();
    };

    // currentSelectionInfo（選択中の予定）の内容を編集する
    // type(String)ごとに処理を変える
    const updateCurrentSelectionInfo = (type, ele) => {
        if (type === "start") {
            let addOneHourEle = ele.add(1, "hour");
            if (currentSelectionInfo.allDay) {
                setCurrentSelectionInfo((prevInfo) => ({
                    ...prevInfo,
                    start: ele.$d,
                    startStr: ele.format("YYYY-MM-DD"),
                    // endもズラしておく
                    end: addOneHourEle.$d,
                    endStr: addOneHourEle.format("YYYY-MM-DD"),
                }));
            } else {
                setCurrentSelectionInfo((prevInfo) => ({
                    ...prevInfo,
                    start: ele.$d,
                    startStr: ele.format("YYYY-MM-DDTHH:mm"),
                    // endもズラしておく
                    end: addOneHourEle.$d,
                    endStr: addOneHourEle.format("YYYY-MM-DDTHH:mm"),
                }));
            }
        }
        if (type === "end") {
            if (currentSelectionInfo.allDay) {
                setCurrentSelectionInfo((prevInfo) => ({
                    ...prevInfo,
                    end: ele.$d,
                    endStr: ele.format("YYYY-MM-DD"),
                }));
            } else {
                setCurrentSelectionInfo((prevInfo) => ({
                    ...prevInfo,
                    end: ele.$d,
                    endStr: ele.format("YYYY-MM-DDTHH:mm"),
                }));
            }
        }
    };

    // CurrentSelectionInfoのAllDayをひっくり返す（時間追加のチェックボックスの制御）
    const handleCurrentAllDay = (event) => {
        // 時間指定チェック入ったとき(event.target.checked=true)、HH:mmは現在付近にする。
        let tmpstartDayjs = {};
        let tmpendDayjs = {};
        if (event.target.checked) {
            const now = dayjs();
            let tmphour = now.hour()
            let tmpminute = Math.ceil(now.minute() / 10) * 10;
            tmpstartDayjs = dayjs(currentSelectionInfo.start).hour(tmphour).minute(tmpminute);
            tmpendDayjs = tmpstartDayjs.add(1, "hour");
            // console.log("tmp", tmpstartDayjs.$d, tmpendDayjs.$d);
        } else {
            tmpstartDayjs = dayjs(currentSelectionInfo.start);
            tmpendDayjs = dayjs(currentSelectionInfo.end);
        }
        // allDayはtrue:全日、false:時間指定なので、反転して登録する
        setCurrentSelectionInfo((prevInfo) => ({
            ...prevInfo,
            start: tmpstartDayjs.$d,
            startStr: event.target.checked ? tmpstartDayjs.format("YYYY-MM-DDTHH:mm"):tmpstartDayjs.format("YYYY-MM-DD"),
            // endもズラしておく
            end: tmpendDayjs.$d,
            endStr: event.target.checked ? tmpendDayjs.format("YYYY-MM-DDTHH:mm"):tmpendDayjs.format("YYYY-MM-DD"),
            allDay: !event.target.checked,
        }));
    };

    // 「他メンバーの編集を許可」のチェックボックスを押した時
    const handleChangeCanAllBeEdited = (event) => {
        setTmpCurrentAllBeEdited(event.target.checked);
    }


    // 作成済みの予定を押した時の動き
    const handleEventClick = (clickInfo) => {
        //console.log(clickInfo);
        setIsEdit(true);
        dayjs.locale(ja);

        // tmpEventにデータ取り出して準備する
        // 検索ダイアログfalseならeventsからコピー、trueならsearchedEventsからコピー

        let tmpEvent = {};
        if (!searchDialog) {
            tmpEvent = _.cloneDeep(
                events.find((ele) => ele.id === clickInfo.event.id)
            );
        } else {
            // 検索ダイアログから来た時はeventを連れてくるので、idnomi 

            tmpEvent = _.cloneDeep(
                searchedEvents.find((ele) => ele.id === clickInfo.id)
            );
        }

        // tmpEvent.allDayがTrueのとき、カレンダー表示のために1日足していた時間を戻す
        if (tmpEvent.allDay) {
            tmpEvent.end = dayjs(tmpEvent.end).subtract(1, "d").format("YYYY-MM-DD");
        }

        //console.log("hogehogehogehoge", unicodeSubstring(tmpEvent.title, 0, 1), unicodeSubstring(tmpEvent.title, 0, 1) === "🔗")


        // tmpEvent.titleの先頭に🔗がついていたら消す
        if (unicodeSubstring(tmpEvent.title, 0, 1) === "🔗") {
            tmpEvent.title = tmpEvent.title.replace("🔗","");
        }

        // tmpEvent.titleが(タイトル未入力)のとき、titleを空白に
        if (tmpEvent.title === "(タイトル未入力)") {
            tmpEvent.title = "";
        }
        // console.log("tmpevent",tmpEvent)

        if (tmpEvent.honbuid === localStorage.getItem('honbuid').toString()) {
            //console.log("本人だよ")
            setCurrentIsAnotherEvent(false);
        } else {
            //console.log("本人じゃないよ")
            setCurrentIsAnotherEvent(true);
        }
        

        // 予定ダイアログ起動時にshare設定も取ってきておく
        handleShareGet(tmpEvent);

        setCurrentSelectionInfo(tmpEvent);
        setCurrentAddText(tmpEvent.addText);
        setCurrentCanAllBeEdited(tmpEvent.canAllBeEdited);
        setTmpCurrentAllBeEdited(tmpEvent.canAllBeEdited);
        setCurrentEventColorObj(tmpEvent.eventColorObj);
        setCurrentEventNotification(tmpEvent.notification);
        setAddEventDialog(true);
    };

    // 絵文字対応のsubstring すぐ上で使ってる
    const unicodeSubstring = (str, start, end) => {
        const reg = new RegExp(`^.{${start}}(.{0,${end - start}})`, 'u')
        return str.match(reg)?.[1]
    }



    // eventがリサイズされた時
    // リサイズ後のデータでevents更新
    // idをもとに、start,endに更新かける
    // 時間指定のあるイベントはリサイズ不可
    const handleEventResize = async (resizedEvent) => {
        console.log("handleEventResize", resizedEvent);

        if (resizedEvent.event.extendedProps.canAllBeEdited === false
            && resizedEvent.event.extendedProps.honbuid !== localStorage.getItem('honbuid').toString()) {
            //console.log("リサイズ 本人じゃないよ")
            if (!isSpDevice) {
                //setSnackbarState([true, "予定を変更しました。"]);
                toast.error('この予定は作成者本人のみ変更が可能です。')
            }
            getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
            handleDateUnselect();
            return;
        }

        const LShonbuid = localStorage.getItem('honbuid')
        const payloadeventid = resizedEvent.event.id
        await fetch(`/share?honbuid=${LShonbuid}&eventid=${payloadeventid}`,
        ).then((response) => response.json()
        ).then(async (responseJson) => {
            //console.log("share get ok", responseJson);
            //let tmpGroupList = responseJson.ret.filter(e => e.isgroup === "1")
            //let tmpContactList = responseJson.ret.filter(e => e.isgroup === "0")
            let tmpShareList = responseJson.ret;

            setShareListLoading(false);
            setEvents((prevState) =>
                prevState.map((obj) =>
                    obj.id === resizedEvent.event.id
                        ? {
                            ...obj,
                            start: resizedEvent.event.startStr,
                            end: resizedEvent.event.endStr,
                        }
                        : obj
                )
            );
            // id付きでpost 鯖側でdeleteinsert
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: resizedEvent.event.id,
                    groupId: localStorage.getItem('roomid'),
                    honbuid: LShonbuid,
                    title: resizedEvent.event.title,
                    start: dayjs(resizedEvent.event.startStr).format("YYYY/MM/DD 00:00:00"),
                    end: dayjs(resizedEvent.event.endStr).format("YYYY/MM/DD 00:00:00"),
                    allDay: resizedEvent.event.allDay ? "1" : "0",
                    backgroundColor: resizedEvent.event.backgroundColor,
                    eventColorObj: JSON.stringify(resizedEvent.event.extendedProps.eventColorObj),
                    borderColor: resizedEvent.event.borderColor,
                    addText: resizedEvent.event.extendedProps.addText,
                    canAllBeEdited: resizedEvent.event.extendedProps.canAllBeEdited?"1":"0",
                    notifications: uniqueObjInArr(resizedEvent.event.extendedProps.notification),
                })
            };
            //console.log("post deleteinsert start")
            fetch("/events", requestOptions
            ).then((response) => response.json()
            ).then((responseJson) => {
                // 新しいidを使い、公開設定を再度登録
                //let filtedGroupList = tmpGroupList.filter(ele => { return ele.isshared === "1" })
                //let filtedContactList = tmpContactList.filter(ele => { return ele.isshared === "1" })
                let issharedList = tmpShareList.filter(ele => { return ele.isshared === "1" })
                let retRoomidList = [];
                for (const ele of issharedList) {
                    retRoomidList.push({ roomid: ele.roomid })
                }
                upRoomidToShareWithNewEventid(responseJson, retRoomidList);
                if (!isSpDevice) {
                    //setSnackbarState([true, "予定を変更しました。"]);
                    toast.success('予定を変更しました。')
                }
                // post成功したらget
                getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
                handleDateUnselect();
            }).catch((err) => {
                console.log("resize post deleteinsert error", err);
                handleDateUnselect();
            })
        }).catch((err) => {
            setShareListLoading(false);
            console.log("share get error", err);
            handleDateUnselect();
        })
    };

    // eventがドラッグ＆ドロップされた時
    // ドロップ後のデータでevents更新
    // idをもとに、start,endに更新かける
    const handleEventDrop = async(DropedEvent) => {
        // console.log("handleEventDrop", DropedEvent);

        if (DropedEvent.event.extendedProps.canAllBeEdited === false
            && DropedEvent.event.extendedProps.honbuid !== localStorage.getItem('honbuid').toString()) {
            //console.log("ドロップ 本人じゃないよ")
            if (!isSpDevice) {
                //setSnackbarState([true, "予定を変更しました。"]);
                toast.error('この予定は作成者本人のみ変更可能です。')
            }
            getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
            handleDateUnselect();
            return;
        }

        const LShonbuid = localStorage.getItem('honbuid')
        const payloadeventid = DropedEvent.event.id
        await fetch(`/share?honbuid=${LShonbuid}&eventid=${payloadeventid}`,
        ).then((response) => response.json()
        ).then(async(responseJson) => {
            //console.log("share get ok", responseJson);
            //let tmpGroupList = responseJson.ret.filter(e => e.isgroup === "1")
            //let tmpContactList = responseJson.ret.filter(e => e.isgroup === "0")
            let tmpShareList = responseJson.ret;

            setShareListLoading(false);

            setEvents((prevState) =>
                prevState.map((obj) =>
                    obj.id === DropedEvent.event.id
                        ? {
                            ...obj,
                            start: DropedEvent.event.startStr,
                            end: DropedEvent.event.endStr,
                        }
                        : obj
                )
            );
            // id付きでpost 鯖側でdeleteinsert
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: DropedEvent.event.id,
                    groupId: localStorage.getItem('roomid'),
                    honbuid: LShonbuid,
                    title: DropedEvent.event.title,
                    start: dayjs(DropedEvent.event.startStr).format("YYYY/MM/DD HH:mm:ss"),
                    end: dayjs(DropedEvent.event.endStr).format("YYYY/MM/DD HH:mm:ss"),
                    allDay: DropedEvent.event.allDay ? "1" : "0",
                    backgroundColor: DropedEvent.event.backgroundColor,
                    eventColorObj: JSON.stringify(DropedEvent.event.extendedProps.eventColorObj),
                    borderColor: DropedEvent.event.borderColor,
                    addText: DropedEvent.event.extendedProps.addText,
                    canAllBeEdited: DropedEvent.event.extendedProps.canAllBeEdited?"1":"0",
                    notifications: uniqueObjInArr(DropedEvent.event.extendedProps.notification),
                })
            };
            //console.log("post deleteinsert start")
            fetch("/events", requestOptions
            ).then((response) => response.json()
            ).then((responseJson) => {
                    //console.log("post deleteinsert ok", responseJson);
                    // 新しいidを使い、公開設定を再度登録
                    //let filtedGroupList = tmpGroupList.filter(ele => { return ele.isshared === "1" })
                    //let filtedContactList = tmpContactList.filter(ele => { return ele.isshared === "1" })
                    let issharedList = tmpShareList.filter(ele => { return ele.isshared === "1" })
                    let retRoomidList = [];
                    for (const ele of issharedList) {
                        retRoomidList.push({ roomid: ele.roomid })
                    }
                    upRoomidToShareWithNewEventid(responseJson, retRoomidList);
                    if (!isSpDevice) {
                        //setSnackbarState([true, "予定を変更しました。"]);
                        toast.success('予定を変更しました。')
                    }
                    // post成功したらget
                    getAndApplyEvents(currentPageDateRef.current.start, currentPageDateRef.current.end);
                    handleDateUnselect();
            }).catch((err) => {
                console.log("drop post deleteinsert error", err);
                handleDateUnselect();
            })

        }).catch((err) => {
            setShareListLoading(false);
            console.log("share get error", err);
            handleDateUnselect();
        })
    };

    // 通知系
    // 通知時間追加
    const handleAddNotification = () => {
        setCurrentEventNotification([
            ...currentEventNotification, {"time":
                notificationConfirmFuture()}
        ]);
        
    };
    
    // 通知の初期値をチェック 現在時刻より前になってしまった時、現在時刻10分単位繰上の時間に更新
    const notificationConfirmFuture = () => {
        const currentDateTime = dayjs();
        // 全日の時、デフォの値は予定開始の1日前の午前9時
        // 時間指定の時、デフォの値は予定開始の10分前
        const defaultDateTime = currentSelectionInfo.allDay
            ? dayjs(currentSelectionInfo.start).subtract(1, "day").hour(9)
            : dayjs(currentSelectionInfo.start).subtract(10, "minute")
        // console.log(defaultDateTime.isBefore(currentDateTime));
        if (defaultDateTime.isBefore(currentDateTime)) {
            // 規定の初期値が過去の時間だった場合、初期値を設定し直す            
            const recalculateDateTime = currentSelectionInfo.allDay
                ? currentDateTime.hour(currentDateTime.hour() + 1).minute(0)
                : currentDateTime.minute(Math.ceil(currentDateTime.minute() / 10) * 10);
            //console.log("recalculateDateTime", recalculateDateTime.format("YYYY-MM-DD HH:mm"));
            return recalculateDateTime.format("YYYY-MM-DD HH:mm");
        } else {
            return defaultDateTime.format("YYYY-MM-DD HH:mm");
        }
    }


    // 通知時間変更
    const handleUpdateNotification = (selectedDate, targetIndex) => {
        // targetindexをもとに更新
        setCurrentEventNotification((prevNotification) =>
            prevNotification.map((value, index) =>
                index === targetIndex
                    ? { "time": dayjs(selectedDate).format("YYYY-MM-DD HH:mm") }
                    : value
            )
        );
    };

    // 通知削除
    const handleDeleteNotification = (targetIndex) => {
        // targetindexをもとに削除
        setCurrentEventNotification((prevNotification) =>
            prevNotification.filter((value, index) => index !== targetIndex)
        );
    };

    // 色選択系
    const handleEventColorMenuOpen = () => {
        setEventColorMenuOpen(true);
    };
    const handleEventColorMenuClose = () => {
        setEventColorMenuOpen(false);
    };
    const handleEventColorIsChanged = (eventcolor) => {
        //console.log(eventcolor);
        setCurrentEventColorObj(eventcolor);
        handleEventColorMenuClose();
    };

    // share系
    // sharedialog開いた時、参加中のルームリストを取得
    const handleOpenEventShareDialog = async() => {
        await handleShareGet(currentSelectionInfo);
        setEventShareDialog(true);
    }

    // 参加中のグループ・コンタクトの情報 + share済かget → stateにset
    const handleShareGet = async(selectioninfo) => {
        setShareListLoading(true);
        const LShonbuid = localStorage.getItem('honbuid')
        const LSroomid = localStorage.getItem('roomid')
        const payloadeventid = 'id' in selectioninfo ? selectioninfo.id : "0"
        await fetch(`/share?honbuid=${LShonbuid}&eventid=${payloadeventid}`,
        ).then((response) => response.json()
        ).then((responseJson) => {
            console.log("share get ok", responseJson);
            // 今の部屋はリストに含めない 公開設定が登録された場合、合わせて今の部屋の公開設定も登録している
            // マイルームのみグループから分離
            let tmpMyroomList = responseJson.ret.filter(e => e.ismyroom === "1" && e.roomid !== LSroomid.toString())
            let tmpGroupList = responseJson.ret.filter(e => e.isgroup === "1" && e.ismyroom === "0" && e.roomid !== LSroomid.toString())
            let tmpContactList = responseJson.ret.filter(e => e.ismyroom === "0" && e.isgroup === "0" && e.roomid !== LSroomid.toString())
            let tmpinvisibleSharedRoomidList = responseJson.invisibleSharedRoomidList;
            setShareMyroomList(tmpMyroomList)
            setShareGroupList(tmpGroupList)
            setShareContactList(tmpContactList)
            setShareListLoading(false);
            shareRoomidFilterAndFormat(tmpMyroomList, tmpGroupList, tmpContactList);
            setInvisibleSharedRoomidList(tmpinvisibleSharedRoomidList);
            console.log("hoge", tmpinvisibleSharedRoomidList);
        }).catch((err) => {
            setShareListLoading(false);
            console.log("share get error", err);
        })
        
    }


    // sharedialog閉じた時
    const handleCloseEventShareDialog = () => {
        // list初期化のタイミングは予定ダイアログ閉じた時に変更
        //setShareGroupList([]);
        //setShareContactList([]);
        shareRoomidFilterAndFormat(shareMyroomList, shareGroupList, shareContactList);
        setEventShareDialog(false);
    }

    // sharediarogのlistbutton押された時、対象のissharedを反転
    const handleShareListClick = (type, clickedele) => {
        if (type === "myroom") {
            setShareMyroomList((prevState) =>
                prevState.map((obj) =>
                    obj.roomid === clickedele.roomid
                        ? {
                            ...obj,
                            isshared: clickedele.isshared === "0"
                                ? "1"
                                : "0",
                        }
                        : obj
                )
            );
        }
        if (type==="group") {
            setShareGroupList((prevState) =>
                prevState.map((obj) =>
                    obj.roomid === clickedele.roomid
                        ? {
                            ...obj,
                            isshared: clickedele.isshared === "0"
                                ? "1"
                                : "0",
                        }
                        : obj
                )
            );
        }
        if (type === "contact") {
            setShareContactList((prevState) =>
                prevState.map((obj) =>
                    obj.roomid === clickedele.roomid
                        ? {
                            ...obj,
                            isshared: clickedele.isshared === "0"
                                ? "1"
                                : "0",
                        }
                        : obj
                )
            );
        }
    }

    // 公開対象のroomidを抽出して整形
    const shareRoomidFilterAndFormat = async(argMyroomList ,argGroupList, argContactList) => {
        let filtedMyroomList = argMyroomList.filter(ele => {
            return ele.isshared === "1"
        })
        let filtedGroupList = argGroupList.filter(ele => {
            return ele.isshared === "1"
        })
        let filtedContactList = argContactList.filter(ele => {
            return ele.isshared === "1"
        })

        let issharedList = [...filtedMyroomList, ...filtedGroupList, ...filtedContactList];
        console.log("shareRoomidFilterAndFormat", issharedList)
        // [{roomid:0000},{roomid: 9999}...]のような形にする
        let retRoomidList = [];
        let retRoomnameList = [];
        for (const ele of issharedList) {
            retRoomidList.push({ roomid: ele.roomid })
            retRoomnameList.push({ roomname: ele.roomname })
        }
        await setShareRoomidList(retRoomidList);
        await setShareRoomnameList(retRoomnameList);
    }

    // event post 終了後、返り値の新eventidを使って share post実行
    const upRoomidToShareWithNewEventid = async (newEventid, argShareRoomidList) => {
        // shareRoomidList内容あり && 今のroomidが登録されてない時、今のroomidも追加
        const LSroomid = localStorage.getItem('roomid')
        let requestroomidlist = []

        // argShareRoomidList:ユーザが参加している部屋のうち、共有先として選択された情報
        // ↑だけで更新すると、自分から見えない部屋（相手のマイルームなど）へ共有されているケースに対応できない。
        // /share get時に、見えない部屋への共有情報を取得、storeに逃がしているので、ここで合流させる。
        let combinedShareRoomidList = [...argShareRoomidList, ...invisibleSharedRoomidList]

        if (combinedShareRoomidList.length > 0) {
            var some = combinedShareRoomidList.some((ele) => ele.roomid === LSroomid.toString())
            if (some === false) {
                requestroomidlist = [...combinedShareRoomidList, { roomid: LSroomid.toString() }]
            }
            else {
                requestroomidlist = combinedShareRoomidList
            }
        } else {
            requestroomidlist = combinedShareRoomidList
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventid: newEventid.toString(),
                roomidToShares: requestroomidlist
            })
        };
        console.log("share post deleteinsert start")
        fetch("/share", requestOptions
        ).then((response) => console.log("share post deleteinsert ok", response)
        ).catch((err) => {
            console.log("share post deleteinsert error", err);
        })
    }

    const handleCloseSearchDialog = () => {
        setSearchedMessage("");
        setSearchedEvents([]);
        setSearchDialog(false);
    }

    // 検索用
    const handleSearchFormKeyDown = async(e) => {
        // Enterキーが押された時、検索してフォーカス解除
        if (e.keyCode === 13) {
            console.log(e.target.value);
            e.target.blur();
            // ローディング処理入れる
            const LSroomid = localStorage.getItem('roomid')
            await fetch(`/search?roomid=${LSroomid}&searchwords=${e.target.value}`,
            ).then((response) => response.json()
            ).then((responseJson) => {
                console.log("search get ok", responseJson);
                handleApplyEvent(responseJson.eventslist, responseJson.notislist, "searchedEvents")
                setSearchedMessage(`検索結果：${responseJson.eventslist.length}件`);
            }).catch((err) => {
                setSearchedEvents([]);
                setSearchedMessage("");
                console.log("search get error", err);
            })
        }
    }

    const FormatSearchedSecondary = (event) => {
        if (event.allDay) {
            if (dayjs(event.end).diff(dayjs(event.start), 'day') > 1) {
                // startと end の日単位差分が1以上の時
                let retallmaltiday =
                    dayjs(event.start).format("MM/DD")
                    + " - "
                    + dayjs(event.end).subtract(1, 'd').format("MM/DD")
                    + " 終日";
                return retallmaltiday;
            } else {
                return "終日";
            }
        } else {
            let retStr = "";
            retStr += dayjs(event.start).format("HH:mm");
            retStr += " - ";
            if (dayjs(event.start).format("YYYY-MM-DD") === dayjs(event.end).format("YYYY-MM-DD")) {
                retStr += dayjs(event.end).format("HH:mm");
            } else {
                retStr += dayjs(event.end).format("MM/DD HH:mm");
            }
            return retStr;
        }
    }

    const searchSubHeaderFunc = (event,index) => {
        if (index === 0) {
            return true;
        } else if (dayjs(event.start).format("YYYY-MM-DD") !== dayjs(searchedEvents[index - 1].start).format("YYYY-MM-DD")) {
            return true;
        } else {
            return false;
        }
    }



    // ローカルでの仮処理用 とりあえず連番ID持ってもらう
    function createEventId() {
        setEventGuid(eventGuid + 1);
        return String(eventGuid);
    }


    // 認証失敗 or param不正の場合、簡素なエラー画面を表示
    if (errorDialog[0]) {
        return (
            <div>error {errorDialog[1]}</div>
        )
    }
    
    return (
        <ThemeProvider theme={theme}>
            {/*{isSpDevice ? "モバイルだよ" : "モバイルじゃないよ"}*/}
            <div>
                {/*初回ロード時のくるくる*/}
                {/*<Backdrop*/}
                {/*sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}*/}
                {/*open={circularProgressIsActive}*/}
                {/*>*/}
                {/*    <CircularProgress color="inherit" />    */}
                {/*</Backdrop>*/}

                {/*初回ロード時のくるくる ver2*/}
                <Backdrop
                    sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={circularProgressIsActive}
                >
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </Backdrop>


                {/*ロード時のLinearProgress*/}
                {linearProgressIsActive && (<LinearProgress />)}
                {!linearProgressIsActive && (<Box sx={{ height: 4 }} />)}
                <div {...handlers} style={{ userSelect: "none" }}>
                    {/*このdiv内でswip検知*/}
                    <FullCalendar
                        fixedWeekCount={false}
                        // showNonCurrentDates={false}
                        firstDay={0}
                        height="90vh"
                        //aspectRatio={1.8}
                        headerToolbar={
                            isSpDevice ?
                            {
                                start: "title",
                                center: "",
                                    end: "customSearch listMonth,dayGridMonth",
                            } : {
                            start: "prev,next",
                            center: "title",
                            end: "customSearch listMonth,dayGridMonth",
                            }
                        }
                        // 自作のヘッダーボタン設定
                        customButtons={{
                            customSearch: {
                                text: '検索',
                                click: function () { setSearchDialog(true) }
                            }
                        }}

                        buttonText={{
                            list: "一覧",
                        }}
                        dayCellContent={(e) =>
                            (e.dayNumberText = e.dayNumberText.replace("日", ""))
                        } // 「〇日」の「日」を表示させない
                        datesSet={handleDateChange}
                        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                        initialView={"dayGridMonth"}
                        locales={[jaLocale]}
                        locale="ja"
                        // events={"https://fullcalendar.io/api/demo-feeds/events.json"}
                        events={events}
                        eventOrder={"start"}

                        dateClick={isSpDevice && handleDateSelect}
                        editable={isSpDevice ? false : true }
                        selectable={ isSpDevice ? false : true } // スマホの時は複数選択無効
                        select={isSpDevice ? handleDateUnselect : handleDateSelect}
                        droppable={ isSpDevice ? false : true } // スマホの時はイベントのドラッグ移動無効
                        ref={calendarRef}
                        eventClick={handleEventClick}
                        eventDrop={!isSpDevice && handleEventDrop}
                        eventResize={!isSpDevice && handleEventResize}

                        // selectLongPressDelay={0} // スマホでタップした時速反応
                        // ↑スマホの時はselectではなくdateClickを使うようにしたので不要
                    />
                </div>
                {/* 予定追加ダイアログ */}
                {/*{theme.breakpoints.down('xs')?"モバイルだよ":"モバイルじゃないよ"}*/}
                <Dialog
                    open={addEventDialog}
                    onClose={handleDateUnselect}
                    fullScreen={isSpDevice?true:false }
                    sx={isSpDevice ? {}:{ marginLeft: "auto", marginRight: "auto", width: 500,  }}
                    fullWidth
                >
                    <DialogTitle sx={{ pa: 1 }}>
                        {isSpDevice ? (
                            //スマホの時
                            <Grid container sx={{px:1, my:1} }>
                                <Grid item xs="auto">
                                    <Button color="info" onClick={handleDateUnselect}>
                                    {/*<Button color="info" size="small" onClick={() => console.log("calcel clicked")}>*/}
                                        {currentIsAnotherEvent ? (<>戻る</>) : (<>キャンセル</>)}
                                    </Button>
                                </Grid>
                                <Grid item xs></Grid>
                                {(!currentIsAnotherEvent || currentCanAllBeEdited) && (
                                    <Grid item xs="auto">
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            onClick={isEdit ? handleEditEvent : handleAddEvent}
                                        >
                                            {isEdit ? "更新" : "追加"}
                                        </Button>
                                    </Grid>
                                    )
                                }
                            </Grid>
                        ) : (
                            //スマホじゃない時
                                <Grid container>
                                    <Grid item xs></Grid>
                                    <Grid item xs="auto">
                                        <IconButton
                                            color="BGgrey500"
                                            size="small"
                                            // onClick={() => console.log("piyopiyo")}
                                            onClick={handleDateUnselect}
                                            sx={{mr:0.5} }
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                        ) 
                    }

                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{ pt:1, pb:2 }}>
                        <Grid container>
                            <Grid item xs={1.5}></Grid>
                            <Grid item xs mb={2}>
                            <TextField
                                    autoFocus={isEdit?false:true }
                                    margin="dense"
                                    id="addEventName"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    placeholder="タイトルを追加"
                                    defaultValue={currentSelectionInfo.title}
                                    // onChange={(event) => setEventTitle(event.target.value)}
                                    onBlur={(event) =>
                                        setCurrentSelectionInfo((prevInfo) => ({
                                            ...prevInfo,
                                            title: event.target.value,
                                        }))
                                    }
                                    style={{ cursor: "pointer" }}
                                    autoComplete="off" //履歴鬱陶しいのでオートコンプリートをオフに
                                    inputProps={{ readOnly: currentIsAnotherEvent && !currentCanAllBeEdited ?true:false }}
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="center">
                            <Grid item xs={1.5}>
                                <AccessTimeIcon fontSize="small" />
                            </Grid>
                            <Grid item xs>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="ja"
                                    dateFormats={{ monthAndYear: "YYYY年M月" }} //カレンダー左上の日付表示
                                    localeText={{
                                        previousMonth: "前月を表示", // < のツールチップ
                                        nextMonth: "次月を表示", // > のツールチップ
                                    }}
                                    sx={{
                                        "& ::-webkit-scrollbar": {
                                            display: "none",
                                        },
                                        // "& :hover": {
                                        //   "::-webkit-scrollbar": {
                                        //     display: "inline",
                                        //   },
                                        // },
                                    }}
                                >
                                    <Stack spacing={1}>
                                        <Grid container alignItems="center" justify="center">
                                            <Grid item xs="auto" sx={{
                                                marginRight: 1,
                                                }}>
                                                開始
                                            </Grid>
                                            <Grid item xs>
                                                {/*編集可能な予定&&全日の時*/}
                                                {(!currentIsAnotherEvent || currentCanAllBeEdited) && currentSelectionInfo.allDay && (
                                                    <ButtonDatePicker
                                                        label={`${!currentSelectionInfo.start
                                                                ? "　"
                                                                : dayjs(currentSelectionInfo.start).format(
                                                                    "MM月DD日"
                                                                )
                                                            }`}
                                                        value={dayjs(currentSelectionInfo.start)}
                                                        onChange={(event) =>
                                                            updateCurrentSelectionInfo("start", event)
                                                        }
                                                    />
                                                )}
                                                {/*編集可能な予定&&時間指定の時*/}
                                                {(!currentIsAnotherEvent || currentCanAllBeEdited) && !currentSelectionInfo.allDay && (
                                                    <ButtonDateTimePicker
                                                        label={`${!currentSelectionInfo.start
                                                                ? "　"
                                                                : dayjs(currentSelectionInfo.start).format(
                                                                    "MM月DD日 HH:mm"
                                                                )
                                                            }`}
                                                        value={dayjs(currentSelectionInfo.start)}
                                                        onChange={(event) =>
                                                            updateCurrentSelectionInfo("start", event)
                                                        }
                                                    />
                                                )}
                                                {/*編集不可の予定&&全日の時*/}
                                                {currentIsAnotherEvent && !currentCanAllBeEdited && currentSelectionInfo.allDay && (
                                                    <Button
                                                        label={`${!currentSelectionInfo.start
                                                            ? "　"
                                                            : dayjs(currentSelectionInfo.start).format(
                                                                "MM月DD日"
                                                            )
                                                            }`}
                                                        variant="outlined"
                                                        sx={{ py: 0.25 }}
                                                    >
                                                        {`${!currentSelectionInfo.start
                                                            ? "　"
                                                            : dayjs(currentSelectionInfo.start).format(
                                                                "MM月DD日"
                                                            )
                                                            }`}
                                                    </Button>
                                                )}
                                                {/*編集不可の予定&&時間指定の時*/}
                                                {currentIsAnotherEvent && !currentCanAllBeEdited && !currentSelectionInfo.allDay && (
                                                    <Button
                                                        label={`${!currentSelectionInfo.start
                                                            ? "　"
                                                            : dayjs(currentSelectionInfo.start).format(
                                                                "MM月DD日 HH:mm"
                                                            )
                                                            }`}
                                                        variant="outlined"
                                                        sx={{ py: 0.25 }}
                                                    >
                                                        {`${!currentSelectionInfo.start
                                                            ? "　"
                                                            : dayjs(currentSelectionInfo.start).format(
                                                                "MM月DD日 HH:mm"
                                                            )
                                                            }`}
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack spacing={1}>
                                        <Grid container alignItems="center" justify="center" sx={{mt:0.75}}>
                                            <Grid item xs="auto" sx={{
                                                marginRight: 1,
                                            }} >
                                                終了
                                            </Grid>
                                            <Grid item xs>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                    adapterLocale="ja"
                                                    dateFormats={{ monthAndYear: "YYYY年 MM月" }} //カレンダー左上の日付表示
                                                    localeText={{
                                                        previousMonth: "前月を表示", // < のツールチップ
                                                        nextMonth: "次月を表示", // > のツールチップ
                                                    }}
                                                    sx={{
                                                        "& ::-webkit-scrollbar": {
                                                            display: "none",
                                                        },
                                                        // "& :hover": {
                                                        //   "::-webkit-scrollbar": {
                                                        //     display: "inline",
                                                        //   },
                                                        // },
                                                    }}
                                                >
                                                    {(!currentIsAnotherEvent || currentCanAllBeEdited) && currentSelectionInfo.allDay && (
                                                        <ButtonDatePicker
                                                            label={`${!currentSelectionInfo.end
                                                                    ? "　"
                                                                    : dayjs(currentSelectionInfo.end).format(
                                                                        "MM月DD日"
                                                                    )
                                                                }`}
                                                            value={dayjs(currentSelectionInfo.end)}
                                                            onChange={(event) =>
                                                                updateCurrentSelectionInfo("end", event)
                                                            }
                                                            // endのみminDate指定
                                                            minDate={dayjs(currentSelectionInfo.start)}
                                                        />
                                                    )}
                                                    {(!currentIsAnotherEvent || currentCanAllBeEdited) && !currentSelectionInfo.allDay && (
                                                        <ButtonDateTimePicker
                                                            label={`${!currentSelectionInfo.end
                                                                    ? "　"
                                                                    : dayjs(currentSelectionInfo.end).format(
                                                                        "MM月DD日 HH:mm"
                                                                    )
                                                                }`}
                                                            value={dayjs(currentSelectionInfo.end)}
                                                            onChange={(event) =>
                                                                updateCurrentSelectionInfo("end", event)
                                                            }
                                                            // endのみminDate指定
                                                            minDateTime={dayjs(currentSelectionInfo.start)}
                                                        />
                                                    )}
                                                    {/*編集不可の予定&&全日の時*/}
                                                    {currentIsAnotherEvent && !currentCanAllBeEdited && currentSelectionInfo.allDay && (
                                                        <Button
                                                            label={`${!currentSelectionInfo.end
                                                                ? "　"
                                                                : dayjs(currentSelectionInfo.end).format(
                                                                    "MM月DD日"
                                                                )
                                                                }`}
                                                            variant="outlined"
                                                            sx={{ py: 0.25 }}
                                                        >
                                                            {`${!currentSelectionInfo.end
                                                                ? "　"
                                                                : dayjs(currentSelectionInfo.end).format(
                                                                    "MM月DD日"
                                                                )
                                                                }`}
                                                        </Button>
                                                    )}
                                                    {/*編集不可の予定&&時間指定の時*/}
                                                    {currentIsAnotherEvent && !currentCanAllBeEdited && !currentSelectionInfo.allDay && (
                                                        <Button
                                                            label={`${!currentSelectionInfo.end
                                                                ? "　"
                                                                : dayjs(currentSelectionInfo.end).format(
                                                                    "MM月DD日 HH:mm"
                                                                )
                                                                }`}
                                                            variant="outlined"
                                                            sx={{ py: 0.25 }}
                                                        >
                                                            {`${!currentSelectionInfo.end
                                                                ? "　"
                                                                : dayjs(currentSelectionInfo.end).format(
                                                                    "MM月DD日 HH:mm"
                                                                )
                                                                }`}
                                                        </Button>
                                                    )}
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        {/* チェックボックス */}
                        <Grid container>
                            <Grid item xs={1.5}></Grid>
                            {/* <Grid item xs={1}></Grid> */}
                            <Grid item xs>
                                <FormGroup>
                                    <FormControlLabel
                                        label={<Typography> 時間指定 </Typography>}
                                        control={currentIsAnotherEvent && !currentCanAllBeEdited ?
                                            <Checkbox
                                                checked={!currentSelectionInfo.allDay}
                                                // onChange={handleCurrentAllDay}
                                                disabled
                                            />
                                            : <Checkbox
                                                checked={!currentSelectionInfo.allDay}
                                                onChange={handleCurrentAllDay}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="center">
                            <Grid item xs={1.5}>
                                <EditNoteIcon fontSize="small" />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    id="eventAddText"
                                    type="text"
                                    multiline
                                    fullWidth
                                    variant="standard"
                                    placeholder="説明・URLを追加"
                                    defaultValue={currentAddText}
                                    onBlur={(e) => setCurrentAddText(e.target.value)}
                                    autoComplete="off" //履歴鬱陶しいのでオートコンプリートをオフに
                                    inputProps={{ readOnly: currentIsAnotherEvent && !currentCanAllBeEdited ? true : false }}
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="center" sx={{ mt:"4px"}}>
                            <Grid item xs={1.5}>
                                <NotificationsActiveOutlinedIcon fontSize="small" />
                            </Grid>
                            <Grid item xs>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    {currentEventNotification.length > 0 &&
                                        (!currentIsAnotherEvent || currentCanAllBeEdited) &&
                                        currentEventNotification.map((notificationObj, index) => (
                                            <Grid
                                                container
                                                key={`notiDatetimepicker${notificationObj.time}${index}`}
                                            >
                                                <Grid item xs="auto">
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                        adapterLocale="ja"
                                                        dateFormats={{ monthAndYear: "YYYY年 MM月" }} //カレンダー左上の日付表示
                                                        localeText={{
                                                            previousMonth: "前月を表示", // < のツールチップ
                                                            nextMonth: "次月を表示", // > のツールチップ
                                                        }}
                                                    >
                                                        <NotificationDateTimePicker
                                                            label={`${!notificationObj.time
                                                                    ? "　"
                                                                    : dayjs(notificationObj.time).format(
                                                                        "MM月DD日 HH:mm"
                                                                    )
                                                                }`}
                                                            value={dayjs(notificationObj.time)}
                                                            onAccept={(selectedDate) =>
                                                                handleUpdateNotification(selectedDate, index)
                                                            }
                                                            openTo={"hours"}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    {/* 通知削除の✕ */}
                                                    <IconButton
                                                        color="BGgrey500"
                                                        size="small"
                                                        // onClick={() => console.log("piyopiyo")}
                                                        onClick={() => handleDeleteNotification(index)}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs></Grid>
                                            </Grid>
                                        ))}
                                    {/*編集不可の予定*/}
                                    {currentEventNotification.length > 0 && currentIsAnotherEvent && !currentCanAllBeEdited &&
                                        currentEventNotification.map((notificationObj, index) => (
                                            <Grid
                                                container
                                                key={`notiDatetimepicker${notificationObj.time}${index}`}
                                            >
                                                <Grid item xs="auto">
                                                    <Button
                                                        label={`${!notificationObj.time
                                                            ? "　"
                                                            : dayjs(notificationObj.time).format(
                                                                "MM月DD日 HH:mm"
                                                            )}`}
                                                        color="inherit"
                                                        size="small"
                                                    >
                                                        {`${!notificationObj.time
                                                            ? "　"
                                                            : dayjs(notificationObj.time).format(
                                                                "MM月DD日 HH:mm"
                                                            )}`}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs></Grid>
                                            </Grid>
                                        ))}
                                    {currentEventNotification.length === 0 && currentIsAnotherEvent && !currentCanAllBeEdited && (
                                        <Button
                                            size="small"
                                            color="inherit"
                                            sx={{ px: 0 }}
                                        >
                                            グループ通知設定なし
                                        </Button>
                                    )}
                                    {/* なんやかんやしてeventsに放り込むようにする */}
                                    {/* {currentEventNotification.length} */}
                                </Stack>
                                {(!currentIsAnotherEvent || currentCanAllBeEdited) && (
                                    <Button
                                        size="small"
                                        color="inherit"
                                        onClick={handleAddNotification}
                                        sx={{px:0} }
                                    >
                                        グループ通知を追加
                                    </Button>
                                )}

                            </Grid>
                        </Grid>
                        {(!currentIsAnotherEvent || currentCanAllBeEdited) && (
                            <Grid container alignItems="center" justify="center" sx={{ mt: "4px" }}>
                                <Grid item xs={1.5}>
                                    <LinkIcon fontSize="small" />
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        size="small"
                                        color="inherit"
                                        onClick={handleOpenEventShareDialog}
                                        sx={{ px: 0 }}
                                    >
                                        公開先の追加・変更
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography variant="caption" display="block" >
                                        {shareRoomidList.length > 0 && (
                                            <>（{shareRoomidList.length }件）</>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs></Grid>
                            </Grid>
                        )}
                        {currentSelectionInfo.name && (
                            <Grid container alignItems="center" justify="center" sx={{ mt: "4px" }}>
                                <Grid item xs={1.5}>
                                    <EventNoteOutlinedIcon fontSize="small" />
                                </Grid>
                                <Grid item xs="auto" sx={{ fontSize: 14 }}>
                                    {currentCanAllBeEdited
                                        ? (<>最終更新者：{currentSelectionInfo.name}</>)
                                        : (<>作成者：{currentSelectionInfo.name}</>)}
                                </Grid>
                                <Grid item xs></Grid>
                            </Grid>
                        )}
                        {(!currentIsAnotherEvent || currentCanAllBeEdited) && (
                            <Grid container alignItems="center" justify="center" sx={{ mt: "4px" }}>
                                <Grid item xs={1.5}>
                                    <ColorLensIcon fontSize="small" />
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="BGgrey"
                                        onClick={handleEventColorMenuOpen}
                                        // ボタンのHTML要素取得
                                        ref={anchorEl}
                                        endIcon={<ArrowDropDownIcon />}
                                        sx={{py:"2px", px:"0px"} }
                                    >
                                        <Brightness1Icon
                                            color={currentEventColorObj.name}
                                            fontSize="small"
                                        />
                                    </Button>
                                    {/* https://zenn.dev/koharu2739/articles/353d06dfb91aae */}
                                    {/* ↑を参考にmenuの画面構成作る */}
                                    <Menu
                                        anchorEl={anchorEl.current}
                                        open={eventColorMenuOpen}
                                        onClose={handleEventColorMenuClose}
                                        // 紐づけたHTML要素のどこを標準位置にしてメニューを配置するか設定できる
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        // メニューの起点を設定できる。アニメーションもこの起点から生えるように出現する
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                    >
                                        <Card sx={{ minWidth: 275 }}>
                                            <CardContent>
                                                <Stack direction="row">
                                                    {/* ここにカラパレ突っ込む */}
                                                    {eventColorMember.map((eventcolor) => (
                                                        <IconButton
                                                            key={`eventcolorselectkey${eventcolor.name}`}
                                                            aria-label={`eventcolorselect${eventcolor.name}`}
                                                            color={eventcolor.name}
                                                            size="small"
                                                            sx={{ p: 0 }}
                                                            onClick={() =>
                                                                handleEventColorIsChanged(eventcolor)
                                                            }
                                                        >
                                                            <Brightness1Icon />
                                                        </IconButton>
                                                    ))}
                                                </Stack>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" onClick={handleEventColorMenuClose}>
                                                    戻る
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Menu>
                                </Grid>
                                <Grid item xs></Grid>
                            </Grid>
                        )}
                        {/*作成者が自分&&編集許可してない時のみ出現*/}
                        {(!currentIsAnotherEvent && !currentCanAllBeEdited) && (
                            <Grid container alignItems="center" justify="center" sx={{ mt: "4px" }}>
                                <Grid item xs={1.5}><PeopleOutlineIcon fontSize="small" /></Grid>
                                {/* <Grid item xs={1}></Grid> */}
                                <Grid item xs>
                                    <FormGroup>
                                        <FormControlLabel
                                            label={<Typography variant="caption"> 他メンバーの編集・削除を許可 </Typography>}
                                            control={
                                                <Checkbox
                                                    checked={tmpCurrentAllBeEdited}
                                                    onChange={handleChangeCanAllBeEdited}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        )}



                        {isSpDevice && isEdit && (!currentIsAnotherEvent || currentCanAllBeEdited) &&(
                            //スマホの削除ボタン
                            <Grid container sx={{mt:"30px"} }>
                                <Grid item xs></Grid>
                                <Grid item xs="auto">
                                    {/*<Button onClick={handleDeleteEvent} style={{ cursor: "pointer" }}>*/}
                                    <Button onClick={()=>setConfirmToDelete(true)} style={{ cursor: "pointer" }}>
                                        <p style={{ color: "red", margin: 0 }}>この予定を削除</p>
                                    </Button>
                                </Grid>
                                <Grid item xs></Grid>
                            </Grid>
                        )}
                        {isSpDevice && isEdit && currentIsAnotherEvent && !currentCanAllBeEdited && (
                            //他人の予定なので編集不可
                            <Grid container sx={{ mt: "30px" }} alignItems="center" justify="center">
                                <Grid item sx={{mr:"4px"} }><InfoOutlinedIcon size="small" /></Grid>
                                <Grid item>
                                    <Typography variant="caption" display="block" >
                                        予定の作成者ではないため、編集・削除は出来ません。
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}


                    </DialogContent>
                    {!isSpDevice && (<Divider />) }
                    {!isSpDevice && (!currentIsAnotherEvent || currentCanAllBeEdited) && (
                        <DialogActions>
                            {isEdit && (
                                //<Button onClick={handleDeleteEvent} style={{ cursor: "pointer" }}>
                                <Button onClick={()=>setConfirmToDelete(true)} style={{ cursor: "pointer" }}>
                                    <p style={{ color: "red", margin:0 }}>削除</p>
                                </Button>
                            )}
                            <div style={{ flex: "1 0 0" }} />
                            {/*<Button onClick={handleDateUnselect} style={{ cursor: "pointer" }}>*/}
                            {/*    取り消し*/}
                            {/*</Button>*/}
                            <Button
                                variant="contained"
                                color="info"
                                onClick={isEdit ? handleEditEvent : handleAddEvent}
                                sx={{ py:"3px" }}
                            >
                                {isEdit ? "更新" : "追加"}
                            </Button>
                        </DialogActions>
                    )}
                    {!isSpDevice && currentIsAnotherEvent && !currentCanAllBeEdited && (
                        <DialogActions>
                            <Grid container alignItems="center" justify="center" sx={{px:isSpDevice?0:2 }}>
                                <Grid item xs={1}><InfoOutlinedIcon /></Grid>
                                <Grid item xs="auto">
                                    <Typography variant="caption" display="block" gutterBottom >
                                        この予定の作成者ではないため、編集・削除は出来ません。
                                    </Typography>
                                </Grid>
                                <Grid item xs></Grid>
                            </Grid>
                        </DialogActions>
                    )}
                </Dialog>
                <Dialog open={confirmToDelete} onClose={() => setConfirmToDelete(false)}>
                    <DialogContent sx={{pt:"20px"}}>この予定を削除します。</DialogContent>
                    <DialogActions>
                        <Grid container sx={{pa:0}}>
                            <Grid item xs="auto">
                                <Button onClick={()=>setConfirmToDelete(false)} style={{ cursor: "pointer" }}>
                                    戻る
                                </Button>
                            </Grid>
                            <Grid item xs></Grid>
                            <Grid item xs="auto">
                                <Button　color="error" onClick={()=>handleDeleteEvent()} style={{ cursor: "pointer" }}>
                                    削除
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={eventShareDialog}
                    onClose={handleCloseEventShareDialog}
                    fullScreen={isSpDevice ? true : false}
                    sx={isSpDevice ? {} : { marginLeft: "auto", marginRight: "auto", width: 400, marginTop: "auto", marginBottom: "auto", height:550 }}
                    fullWidth
                >
                    <DialogTitle>
                        <Grid container sx={{ pl:2, pr: 1, my: 1 }}>
                            <Grid item xs="auto" sx={{ mt: "auto", mb: "auto" }}>
                                <Typography variant="subtitle2" >
                                    予定の公開先を選択
                                </Typography>
                            </Grid>
                            <Grid item xs></Grid>
                            <Grid item xs="auto">
                                <Button
                                    onClick={handleCloseEventShareDialog}
                                >
                                    変更して戻る
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider />
                    
                        {/*share〇〇list取得中はローディング噛ませる*/}
                    {shareListLoading ?
                        (
                            <DialogContent sx={{ height: 438, mt: "10px" }}>
                                グループ
                                <Divider />
                                <Grid container>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs>
                                        <div style={{ height: 64, py: "5px" }}>
                                            <Skeleton height={54} variant="rectangular" animation="wave" />
                                        </div>
                                        <Divider />
                                        <div style={{ height: 64, py: "5px" }}>
                                            <Skeleton height={54} variant="rectangular" animation="wave" />
                                        </div>
                                        <Divider />
                                        <div style={{ height: 64, py: "5px" }}>
                                            <Skeleton height={54} variant="rectangular" animation="wave" />
                                        </div>
                                        <Divider />
                                        <div style={{ height: 64, py: "5px" }}>
                                            <Skeleton height={54} variant="rectangular" animation="wave" />
                                        </div>
                                        <Divider />
                                        <div style={{ height: 64, py: "5px" }}>
                                            <Skeleton height={54} variant="rectangular" animation="wave" />
                                        </div>
                                        <Divider />
                                        <div style={{ height: 64, py: "5px" }}>
                                            <Skeleton height={54} variant="rectangular" animation="wave" />
                                        </div>
                                        <Divider />
                                        <div style={{ height: 64, py: "5px" }}>
                                            <Skeleton height={54} variant="rectangular" animation="wave" />
                                        </div>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>
                                </Grid>
                            </DialogContent>
                        ) : (
                            <DialogContent sx={{ pt: "10px" }} >
                                {/*{shareRoomnameList.length > 0 && (*/}
                                    
                                {/*        <Grid container>*/}
                                {/*            <Grid item xs="auto">選択中：</Grid>*/}

                                {/*            {shareRoomnameList.map((ele) => */}
                                {/*                <Grid item xs="auto" key={`shareele${ele.roomname}` } >{ele.roomname }</Grid>*/}
                                {/*            )}*/}
                                {/*            <Grid item xs="auto"></Grid>*/}
                                {/*        </Grid>*/}
                                {/*)}*/}
                                {shareMyroomList.length > 0 && (
                                    <React.Fragment>
                                        個人
                                        <Divider />
                                        <Grid container sx={{ mb: "10px" }}>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs>
                                                <List sx={{ width: '100%' }}>
                                                    {shareMyroomList.map((ele) =>
                                                        <React.Fragment key={`myele${ele.roomid}`}>
                                                            <ListItem
                                                                secondaryAction={
                                                                    <Checkbox
                                                                        edge="end"
                                                                        onChange={() => handleShareListClick("myroom", ele)}
                                                                        checked={ele.isshared === "1" ? true : false}
                                                                    />
                                                                }
                                                                alignItems="flex-start"
                                                            >

                                                                <ListItemAvatar sx={{ mt: "auto", mb: "auto" }}>
                                                                    <Avatar
                                                                        src={`https://chat-tis.com/Static/downloadAvatar?honbuid=999998&size=small`}
                                                                        sx={{ width: 24, height: 24 }}
                                                                    />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    disableTypography={true}
                                                                    primary={"個人カレンダー"}
                                                                    sx={{ fontSize: "14px" }}
                                                                />

                                                            </ListItem>
                                                            <Divider />
                                                        </React.Fragment>
                                                    )}
                                                </List>
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                グループ
                                <Divider />
                                <Grid container sx={{ mb:"10px"}}>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs>
                                        <List sx={{ width: '100%' }}>
                                            {shareGroupList.map((groupele) =>
                                                <React.Fragment key={`groupele${groupele.roomid}`}>
                                                    <ListItem
                                                        secondaryAction={
                                                            <Checkbox
                                                                edge="end"
                                                                onChange={() => handleShareListClick("group", groupele)}
                                                                checked={ groupele.isshared === "1" ? true : false }
                                                                />
                                                        }
                                                        alignItems="flex-start"
                                                    >
                                                            
                                                        <ListItemAvatar sx={{ mt: "auto", mb: "auto" }}>
                                                            <Avatar
                                                                src={`https://chat-tis.com/Static/downloadRoomAvatar?roomid=${groupele.roomid}&size=small`}
                                                                sx={{ width: 24, height: 24 }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            disableTypography={true}
                                                            primary={groupele.roomname}
                                                            sx={{fontSize: "14px"}}
                                                        />
                                                            
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>
                                </Grid>
                                コンタクト
                                <Divider />
                                <Grid container>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs>
                                        <List sx={{ width: '100%' }}>
                                            {shareContactList.map((contactele) =>
                                                <React.Fragment key={`contactele${contactele.roomid}`}>
                                                    <ListItem secondaryAction={
                                                            <Checkbox
                                                                edge="end"
                                                                onChange={() => handleShareListClick("contact", contactele)}
                                                                checked={contactele.isshared === "1" ? true : false}
                                                            />
                                                        }
                                                        alignItems="flex-start"
                                                    >
                                                        <ListItemAvatar sx={{ mt: "auto", mb: "auto" }}>
                                                            <Avatar
                                                                src={`https://chat-tis.com/Static/downloadAvatar?honbuid=${contactele.partnerHonbuidWhenContact}&size=small`}
                                                                sx={{ width: 24, height: 24 }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            disableTypography={true}
                                                            primary={contactele.roomname}
                                                            sx={{ fontSize: "14px" }}
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>
                                </Grid>
                            </DialogContent>
                        )}
                    <Divider />              
                </Dialog>

                {/*検索ダイアログ*/}
                <Dialog
                    open={searchDialog}
                    onClose={() => handleCloseSearchDialog()}
                    fullScreen={isSpDevice ? true : false}
                    sx={isSpDevice
                        ? {}
                        : {
                            marginLeft: "auto",
                            marginRight: "4vw",
                            width: 400,
                            marginTop: "2vh",
                            marginBottom: "auto",
                            height: 550
                        }}
                    fullWidth
                >
                    <Grid container sx={{ pl: 2, pr: 2, my: 1 }}>
                        <Grid item xs="auto" sx={{ mt: "auto", mb: "auto" ,pl:1 }}>
                            <Typography>
                                予定の検索
                            </Typography>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs="auto">
                            <Button
                                onClick={() => handleCloseSearchDialog()}
                            >
                                <Typography variant="subtitle2" >
                                    戻る
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <DialogContent sx={{height: 450}}>
                        <TextField
                            id="standard-search"
                            label="予定のタイトル"
                            type="search"
                            variant="standard"
                            fullWidth
                            autoFocus={true}
                            //Enter押下時に検索開始 & フォーカスを外す
                            onKeyDown={e => handleSearchFormKeyDown(e)}
                            //onBlur={e => {
                                // フォーカスが外れた時の処理
                            //}}
                        />
                            
                        {/*処理中はローディン表示*/}

                        {/*終わったら結果表示*/}
                        <Typography sx={{mt:1}}>
                            {searchedMessage}
                        </Typography>
                        {searchedEvents.length > 0 && (
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper'
                                }}
                                subheader={<li />}
                            >
                                
                                {searchedEvents.map((event,index) => {
                                    const labelId = `searched-events-list-label-${event.id}`;

                                    return (
                                        <li key={`search-eventid-${event.id}`}>
                                            <ul style={{ padding:0 }}>

                                                {
                                                    searchSubHeaderFunc(event,index) &&
                                                    <ListSubheader sx={{pl:0 ,pb:4 ,mb:0, height:"10px"}}>{dayjs(event.start).format("YYYY/MM/DD")}</ListSubheader>
                                                }
                                                <ListItem
                                                    key={event.id}
                                                    disablePadding    
                                                >
                                                    <ListItemButton
                                                        onClick={() => handleEventClick(event)}
                                                        dense
                                                        sx={{ pl: 0 }}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs="auto" sx={{}}>
                                                                <div style={{
                                                                    backgroundColor: event.backgroundColor,
                                                                    padding: 0,
                                                                    marginRight: 8,
                                                                    width: 4,
                                                                    height: '100%',
                                                                    display: 'inline-block',
                                                                }}></div>
                                                            </Grid>
                                                            <Grid item xs>
                                                                <ListItemText
                                                                    id={labelId}
                                                                    primary={event.title}
                                                                    secondary={FormatSearchedSecondary(event)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemButton>
                                                </ListItem>

                                            </ul>
                                        </li>

                                    );
                                })}
                            </List>
                        )}
                    </DialogContent>
                </Dialog>



                {/*素敵なトースト https://react-hot-toast.com/ */}
                <Toaster
                    position="bottom-left"
                    reverseOrder={true}
                />
            </div>
            </ThemeProvider>
    );
}

// カレンダー上に表示される各予定のレンダー用（使ってない）
// function renderEventContent(eventInfo) {
//   console.log("eventinfo", eventInfo);
//   // tmpTitle: eventのタイトル 設定無しなら（タイトル未入力）に
//   const tmpTitle = eventInfo.event.title
//     ? eventInfo.event.title
//     : "(タイトル未入力)";

//   // eventの左に●を置くか否か 時間指定かつ、日を跨がない予定のとき●設置
//   const isDisplayCircle =
//     eventInfo.timeText !== "" &&
//     dayjs(eventInfo.event._instance.range.end).diff(
//       dayjs(eventInfo.event._instance.range.start),
//       "day"
//     ) < 2
//       ? true
//       : false;

//   return (
//     <>
//       {isDisplayCircle && (
//         <i>
//           <Brightness1Icon
//             // ここはmuiの領域なのでPaletteで定義した色名を使わないといけない 面倒...
//             color={eventInfo.event._def.extendedProps.eventColorObj.name}
//             fontSize="5"
//           />
//         </i>
//       )}
//       <b>{eventInfo.timeText}</b>
//       <i>{tmpTitle}</i>
//     </>
//   );
// }

// ボタンカレンダー用パーツ
function ButtonField(props) {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { "aria-label": ariaLabel } = {},
    } = props;

    return (
        <Button
            variant="outlined"
            id={id}
            disabled={disabled}
            // color={btnColor ? btnColor : ""}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
            sx={{ py: 0.25 }}
        >
            {label ?? "日時を選択"}
        </Button>
    );
}

// ボタンカレンダー用パーツ(date)
function ButtonDatePicker(props) {
    const [open, setOpen] = React.useState(false);
    return (
        <DatePicker
            slots={{ field: ButtonField, ...props.slots }}
            slotProps={{ field: { setOpen }, toolbar: { toolbarFormat: 'M月D日' } }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
    );
}

// ボタンカレンダー用パーツ(datetime)
function ButtonDateTimePicker(props) {
    const [open, setOpen] = React.useState(false);
    return (
        <MobileDateTimePicker
            // ampm={false}
            slots={{ field: ButtonField, ...props.slots }}
            slotProps={{ field: { setOpen }, toolbar: {toolbarFormat:'M月D日'} }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
    );
}

// 通知設定ボタン用パーツ
function notificationButtonField(props) {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { "aria-label": ariaLabel } = {},
    } = props;

    return (
        <Button
            // variant="outlined"

            id={id}
            disabled={disabled}
            color="inherit"
            size="small"
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
        >
            {label ?? "通知日時を選択"}
        </Button>
    );
}

// 通知用ボタンカレンダーパーツ(datetime)
function NotificationDateTimePicker(props) {
    const [open, setOpen] = React.useState(false);
    return (
        <MobileDateTimePicker
            // ampm={false}
            slots={{ field: notificationButtonField, ...props.slots }}
            slotProps={{ field: { setOpen }, toolbar: { toolbarFormat: 'M月D日' } }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            disablePast={true} // 通知のpickerは過去を選択できないように
            minutesStep={5}
        />
    );
}

export default App;
